// Foundation JavaScript
// Documentation can be found at: http://foundation.zurb.com/docs
$(document).foundation();

var string_to_slug = function (str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  var to = 'aaaaaeeeeiiiioooouuuunc------';

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace('.', '-')
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');

  return str;
};

// document ready method
$(function () {
  // MATCH-HEIGHT
  $('.js-eh').matchHeight();
  $('.js-eh-1').matchHeight();
  $('.js-eh-2').matchHeight({
    byRow: false,
  });

  // SLICK-SLIDER
  $('.slider-logos').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    mobileFirst: true,

    responsive: [
      {
        breakpoint: 641,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      // {
      //   breakpoint: 1600,
      //   settings: {
      //     slidesToShow: 7,
      //     slidesToScroll: 1,
      //   }
      // }

      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  });

  $('.slider-mini').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 300,
    // autoplay: true,
    // autoplaySpeed: 5000,
  });

  $('.slider-property').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    asNavFor: '.slider-property-thumbs',
    mobileFirst: true,
    adaptiveHeight: true,
    // infinite: true,

    responsive: [
      {
        breakpoint: 1025,
        settings: {
          fade: true,
          arrows: false,
          infinite: false,
        },
      },

      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  });

  $('.slider-property-thumbs').slick({
    slidesToShow: 4,
    slidesToScroll: 4,
    asNavFor: '.slider-property',
    dots: false,
    // centerMode: true,
    focusOnSelect: true,
    arrows: true,
    infinite: false,
    // mobileFirst: true,

    // responsive: [
    // 	{
    // 		breakpoint: 1025,
    // 		settings: {
    // 			slidesToShow: 4,
    // 			slidesToScroll: 1,
    // 		}
    // 	},

    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
    // ]
  });

  $('.mobile .slider-property .content figure').magnificPopup({
    delegate: 'a',
    type: 'image',
    closeOnBgClick: true,
    gallery: {
      enabled: true,
      tCounter: '', // markup of counter
    },
    callbacks: {
      buildControls: function () {
        // re-appends controls inside the main container
        this.contentContainer.append(this.arrowLeft.add(this.arrowRight));
      },
    },
  });

  $('.popup-images').click(function (e) {
    e.preventDefault();
  });

  // Print button
  $('.print').click(function (e) {
    e.preventDefault();
    window.print();
  });

  // MENU-TRIGGER
  $('.menu-trigger').click(function (e) {
    e.preventDefault();
    e.stopPropagation();

    $('body').toggleClass('menu-opened');
    $(this).toggleClass('active');
  });

  $('.main-menu .menu li.menu-item-has-children > ul').each(function () {
    $(this).prepend('<li class="parent-item"><button class="back">Retour</button></li>');
    var $parent = $(this).parents('li');
    $(this).find(' > .parent-item').append($($parent[0]).find('> a').clone());
  });
  $(document).on('click', '.main-menu .menu li.menu-item-has-children > a', function (e) {
    if ($(window).width() < 1200) {
      e.preventDefault();
      e.stopPropagation();
    }
    var $p = $(this).parent();
    $p.toggleClass('opened');
    $p.find(' > ul').toggleClass('opened');
  });
  $(document).on('click', '.main-menu .menu li.parent-item > .back', function (e) {
    var $p = $(this).parent().parent().parent();
    $p.toggleClass('opened');
    $p.find(' > ul').toggleClass('opened');
  });

  //Extra menu

  $('.extra-menu li.menu-item-has-children > ul').each(function () {
    $(this).prepend('<li class="parent-item"><button class="back">Retour</button></li>');
    var $parent = $(this).parents('li');
    $(this).find(' > .parent-item').append($($parent[0]).find('> a').clone());
  });

  $(document).on('click', '.extra-menu li.menu-item-has-children > a', function (e) {
    if ($(window).width() < 1200) {
      e.preventDefault();
      e.stopPropagation();
    }
    var $p = $(this).parent();
    $p.toggleClass('opened');
    $p.find(' > ul').toggleClass('opened');
  });

  $(document).on('click', '.extra-menu li.parent-item > .back', function (e) {
    var $p = $(this).parent().parent().parent();
    $p.toggleClass('opened');
    $p.find(' > ul').toggleClass('opened');
  });

  if ($('.current-menu-item, .current-menu-parent, .current-page-ancestor').length) {
    $('body').addClass('menu-is-open');
  }

  $(
    '.mobile .menu > .menu-item-has-children > a, .mobile .extra-menu > .menu-item-has-children > a, .mobile .level-1 > .menu-item-has-children > a'
  ).click(function (e) {
    if ($(window).width() > 1199) {
      if ($(this).hasClass('go-ahead')) {
        return true;
      } else {
        e.preventDefault();
        $(this).addClass('go-ahead');
      }
    }
  });

  var input_lbl_before_change = '';
  $(document).on('change', 'input[name="input_12"]', function () {
    var v = $(this).val();
    if (v) {
      var basename = v.replace(/\\/g, '/').replace(/.*\//, ''),
        $lip = $(this).parents('li.upload-file'),
        $lbl = $lip.find('label'),
        $fnlbl = $lbl.find('b');
      if ($fnlbl.length) {
        $lbl.html('<b>' + basename + '</b>');
      } else {
        $lip.append('<a href="#" class="close-btn">x</a>');
        input_lbl_before_change = $lbl.html();
        $lbl.html('<b>' + basename + '</b>');
      }
    } else {
      var $lip = $(this).parents('li.upload-file'),
        $lbl = $lip.find('label');
      $lip.find('a.close-btn').remove();
      $lbl.html(input_lbl_before_change);
    }

    // setTimeout(function() {

    // });
  });

  $(document).on('click', 'li.upload-file a.close-btn', function (e) {
    e.preventDefault();
    e.stopPropagation();
    $('input[name="input_12"]').val('').trigger('change');
  });

  // SHARE-TRIGGER
  $('.share-trigger').click(function (e) {
    e.preventDefault();
    e.stopPropagation();

    $('.print-share').toggleClass('opened');
    $(this).toggleClass('active');
  });

  // SEARCH-TRIGGER
  $('.search-trigger').click(function (e) {
    e.preventDefault();
    e.stopPropagation();

    $('.sf-wrap').toggleClass('search-opened');
    $(this).toggleClass('active');
  });

  $('.change-view li a').click(function (e) {
    e.preventDefault();
    $(this).closest('li').addClass('active').siblings().removeClass('active');
  });
  $('.change-view .cv-carte a, .show-on-map').click(function (e) {
    e.preventDefault();
    $('.section-acheter').addClass('map-shown');

    if ($('.properties-map').length && !$('.properties-map').hasClass('map-loaded')) {
      render_map($('.properties-map'));
      $('.properties-map').addClass('map-loaded');
    }
    $('input[name="map"]').prop('disabled', false);

    if (~window.location.href.indexOf('?') && window.location.href.indexOf('map') < 0) {
      var newUrl = window.location.href + '&map=1';
    } else if (window.location.href.indexOf('map') >= 0) {
      var newUrl = window.location.href;
    } else {
      var newUrl = window.location.href + '?map=1';
    }
    window.history.replaceState(null, null, newUrl);
  });
  $('.change-view .cv-list a, .close-map').click(function (e) {
    e.preventDefault();
    $('.section-acheter').removeClass('map-shown');
    $('input[name="map"]').prop('disabled', true);

    if (~window.location.href.indexOf('map=1')) {
      var newUrl = window.location.href.replace('map=1', '');
      window.history.replaceState(null, null, newUrl);
    }
  });

  $('#filter-property').change(function () {
    var fValue = $(this).val();
    window.location.href = fValue;
  });

  // SEARCH-TRIGGER
  $('.mobile-map-trigger').click(function (e) {
    e.preventDefault();
    e.stopPropagation();

    $('body').toggleClass('prop-map-opened');
    // $(this).toggleClass('active');
  });

  // FILTER-PROPERTIES-TRIGGER
  $('.fp-trigger').click(function (e) {
    e.preventDefault();
    e.stopPropagation();

    $('body').toggleClass('mobile-filters-opened');
    $(this).toggleClass('active');
  });

  // SHARE-TRIGGER
  $('.more-filters-trigger').click(function (e) {
    e.preventDefault();
    e.stopPropagation();

    $('.more-filters').toggleClass('opened');
    $(this).toggleClass('active');
  });

  // STICKY HEADER

  var $blockStickyMode = 0;
  $(document).on('scroll', function () {
    if (!$blockStickyMode) {
      setHeaderSticky();
    }
  });
  $('body').on('scroll', function () {
    if (!$blockStickyMode) {
      setHeaderSticky();
    }
  });

  setHeaderSticky();

  function setHeaderSticky() {
    var st = $(document).scrollTop();
    if (st == 0) st = $(window).scrollTop();
    // console.log(
    //   '.inner-content' + '::'+$('.inner-content').scrollTop()+
    //   ', .page-container' + '::'+$('.page-container').scrollTop()+
    //   ', .page-content' + '::'+$('.page-content').scrollTop()+
    //   ', body' + '::'+$('body').scrollTop()+
    //   ', html' + '::'+$('html').scrollTop()+
    //   ', window' + '::'+$(window).scrollTop()+
    //   ', document' + '::'+$(document).scrollTop());

    if (st > 0) {
      $('body').addClass('header-sticky');
      if (st >= $(window).height() - 90) {
        $('body').addClass('after-1-screen');
      } else {
        $('body').removeClass('after-1-screen');
      }
    } else {
      $('body').removeClass('after-1-screen header-sticky');
    }
  }

  // MAP
  // if ($('.map').length) {
  //   render_map($('.map'));
  // }

  if ($('.page-template-page-contact').length) {
    var ht = window.location.hash;

    if (ht !== '') {
      $('.page-template-page-contact select > option').each(function () {
        if (string_to_slug(this.value) === string_to_slug(decodeURI(ht))) {
          $(this)
            .removeAttr('selected')
            .filter('[value="' + this.value + '"]')
            .attr('selected', true);

          return false;
        }
      });
    }
  }

  //  custom-select
  // $('.sidebar-filters select').selects({
  //   // skipFirst: true
  // });
  // //  custom-select
  // $('.gform_wrapper select').selects({
  //   // skipFirst: true
  // });
  // //  custom-select
  // $('.search-bar-container select').selects({
  //   // skipFirst: true
  // });

  // $('select').each(function () {
  //   // console.log($(this).parents('form').attr('id'));
  //   if ($(this).parents('form').attr('id') !== 'property_search_form') {
  //     $(this).select2({
  //       minimumResultsForSearch: -1,
  //     });
  //   }
  // });

  // First option for select to be empty
  // $('.ginput_container_select select').select2({
  //     placeholder: "--",
  //     minimumResultsForSearch: -1
  // });

  // SCROLL TO CATEGORY LINK - FAQ PAGE
  var $header = $('.ph-inner'),
    headerH = $header.height();

  $(document).on('change', '.search-bar-container select', function (e) {
    var $s = $(this),
      val = $s.val();

    // Does some stuff and logs the event to the console
    $('html, body')
      .stop()
      .animate(
        {
          scrollTop: $('#' + val).offset().top - (headerH + 10),
        },
        600
      );
  });

  // SCROLL TO NEXT SECTION
  var $auto_next_section = null;
  // if ( $('.link-next-section').length > 0 ) {
  //  var $t = $('.link-next-section');
  //  console.log($t);
  //  $auto_next_section = setInterval(function() { scrollToNextSection($t); }, 6000);
  //  $(document).on('scroll', function() {
  //    if ( $auto_next_section !== null ) {
  //      clearInterval($auto_next_section);
  //    }
  //    $auto_next_section = null;
  //    scrollToNextSection($('.link-next-section'));
  //  });
  // }

  function scrollToNextSection($t, e) {
    if ($auto_next_section !== null) {
      clearInterval($auto_next_section);
    }

    $auto_next_section = null;

    var $ns = $($t.attr('href'));

    if ($ns.length > 0) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }

      var $ot = $(window).width() <= 640 ? 60 : 50;
      $('html, body')
        .stop()
        .animate(
          {
            scrollTop: $ns.offset().top - $ot,
          },
          600
        );
    }
  }

  $(document).on('click', '.link-next-section', function (e) {
    scrollToNextSection($(this), e);
  });

  // Spaces for contact form - placeholder

  // $('textarea[placeholder]').placeholder();

  $('.fancybox.image').magnificPopup({
    gallery: {
      enabled: true,
      tCounter: '',
    },
    callbacks: {
      buildControls: function () {
        // re-appends controls inside the main container
        this.contentContainer.append(this.arrowLeft.add(this.arrowRight));
      },
    },
    type: 'image', // this is default type
  });

  if ($('.gallery-grid').length > 0) {
    $('.gallery-grid').on('arrangeComplete', function (event, filteredItems) {
      setTimeout(function () {
        $(filteredItems).each(function () {
          $(this.element).addClass('loaded');
        });
      }, 600);
      // filteredItems.addClass('loaded');
      // console.log(  );
    });

    $('.gallery-grid').imagesLoaded(function () {
      $('.gallery-grid').isotope({
        itemSelector: '.grid-item',
        gutter: 14,
        // no transitions
        transitionDuration: 0,
        hiddenStyle: {
          opacity: 0,
        },
        visibleStyle: {
          opacity: 0,
        },
        // layoutMode: 'masonry',
      });
    });

    var $els,
      g_loading_more = 0;

    $(window).on('scroll', function () {
      if (g_loading_more == 0) {
        var st = $('html, body').scrollTop(),
          wh = $(window).height();
        if (st + $(window).height() >= $('.page-footer').offset().top - wh / 2) {
          $('#gallery-load-more').trigger('click');
        }
      }
    });

    $('#gallery-load-more').click(function (e) {
      console.log('loading next set');
      if (g_loading_more == 0) {
        g_loading_more = 1;
        e.preventDefault();
        e.stopPropagation();

        $els = [];
        var $els_hidden = $('.gallery-grid-temp .gi'),
          pgs = $('.gallery-grid-temp').data('pgs');
        // $els_hidden.addClass('not-loaded');

        // console.log(pgs);
        $els_hidden.each(function (index, value) {
          // if ( index >= pgs ) {
          if (index >= 12) {
            // console.log('returned');
            return;
          }
          var $img = $(this).find('img');
          $(this).addClass('grid-item');
          $(this).removeClass('gi');
          $img.attr('src', $img.data('src'));
          $els.push($(this));
        });

        for (var i = 0; i < $els.length; i++) {
          $('.gallery-grid').isotope('insert', $els[i]);
        }

        // console.log($('.gallery-grid-temp .gi').length);
        if ($('.gallery-grid-temp .gi').length <= 0) {
          $('#gallery-load-more').css('display', 'none');
        }

        // $('.gallery-grid .not-loaded').imagesLoaded(function() {
        //   setTimeout(function() {
        //     // $('.gallery-grid .grid-item').addClass('loaded');
        //     g_loading_more -=1 ;
        //   }, 500);
        // });

        setTimeout(function () {
          $('.gallery-grid').isotope('layout');
          // g_loading_more = 0;
          g_loading_more -= 1;
        }, 600);
      }
    });
  }

  function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }

  // ALLERT-TRIGGER
  $('.alertTrigger').click(function (e) {
    e.preventDefault();
    e.stopPropagation();

    let $aw = $('.alert-wrapper');
    $aw.toggleClass('alert-active');
    setCookie('alert-opened', $aw.hasClass('alert-active') ? 1 : 0);
  });

  // Sync text input fields

  $('[class*="copy-data-"] input').blur(function () {
    var el = $(this).closest('.copy-data');
    var className = el.attr('class').match(/copy-data-\d+/); //get a match to match the pattern some-class-somenumber and extract that classname
    if (className) {
      var newVal = $(this).val();
      $('.' + className[0] + ' input').attr('data-storeval', newVal);
      console.log($(this).attr('data-storeval'));
      $('.' + className[0] + ' input').val($(this).attr('data-storeval'));
    }
  });
  $('.ginput_container_select select').change(function () {
    $("[class*='copy-data-']").each(function () {
      var storedVal = $(this).find('input').attr('data-storeval');
      $(this).find('input').val(storedVal);
    });
  });

  // google map

  window.render_map = function ($el) {
    var $markers = $el.find('.marker');

    var args = {
      zoom: 13,
      center: new google.maps.LatLng(0, 0),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scrollwheel: false,
      // styles: []
    };

    var map = new google.maps.Map($el[0], args);
    map.markers = [];
    $markers.each(function () {
      add_marker($(this), map);
    });

    if (map.markers.length > 1) {
      var markerCluster = new MarkerClusterer(map, map.markers, {
        styles: [
          {
            url: $markers.attr('data-img') + 'm1.png',
            width: 35,
            height: 96,
            textSize: 12,
            textColor: '#e71f59',
            anchor: [12, 0],
          },
        ],
      });
    }

    center_map(map);
  };

  var prev_infowindow = false;

  function add_marker($marker, map) {
    var latlng = new google.maps.LatLng(
      $marker.attr('data-lat'),
      $marker.attr('data-lng')
    );

    //get array of markers currently in cluster
    var allMarkers = map.markers;

    //final position for marker, could be updated if another marker already exists in same position
    var finalLatLng = latlng;

    //check to see if any of the existing markers match the latlng of the new marker
    if (allMarkers.length != 0) {
      for (i = 0; i < allMarkers.length; i++) {
        var existingMarker = allMarkers[i];
        var pos = existingMarker.getPosition();

        //if a marker already exists in the same position as this marker
        if (latlng.equals(pos)) {
          //update the position of the coincident marker by applying a small multipler to its coordinates
          var newLat = latlng.lat() + (Math.random() - 0.5) / 1500; // * (Math.random() * (max - min) + min);
          var newLng = latlng.lng() + (Math.random() - 0.5) / 1500; // * (Math.random() * (max - min) + min);
          finalLatLng = new google.maps.LatLng(newLat, newLng);
        }
      }
    }

    var markerLabel = $marker.attr('data-label');

    var infowindow = new google.maps.InfoWindow({
      content: markerLabel,
    });

    var marker = new google.maps.Marker({
      position: finalLatLng,
      map: map,
      animation: google.maps.Animation.DROP,
      icon: $marker.attr('data-img') + 'map-marker.png',
    });
    map.markers.push(marker);

    // marker.addListener('click', toggleBounce);
    // function toggleBounce() {
    //   if (marker.getAnimation() !== null) {
    //     marker.setAnimation(null);
    //   } else {
    //     marker.setAnimation(google.maps.Animation.BOUNCE);
    //   }
    // }

    marker.addListener('click', function () {
      if (prev_infowindow) {
        prev_infowindow.close();
      }
      if ($('.park-map .the-map, .properties-map').length) {
        var newMarker = markerLabel.replace(/data-image="(.*?)"/, 'src="$1"');
        infowindow = new google.maps.InfoWindow({
          content: newMarker,
        });
      }
      prev_infowindow = infowindow;
      marker.setIcon($marker.attr('data-img') + 'map-marker-big.png');
      infowindow.open(map, marker);
    });

    google.maps.event.addListener(map, 'click', function () {
      infowindow.close();
      marker.setIcon($marker.attr('data-img') + 'map-marker.png');
    });

    google.maps.event.addListener(infowindow, 'closeclick', function () {
      marker.setIcon($marker.attr('data-img') + 'map-marker.png');
    });
  }

  function center_map(map) {
    var bounds = new google.maps.LatLngBounds();
    jQuery.each(map.markers, function (i, marker) {
      var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
      bounds.extend(latlng);
    });
    if (map.markers.length == 1) {
      map.setCenter(bounds.getCenter());
      map.setZoom(14);
    } else {
      map.fitBounds(bounds);
    }
  }
  if ($('.property-map').length) {
    render_map($('.property-map'));
  }
  if ($('.park-map .the-map').length) {
    render_map($('.park-map .the-map'));
  }

  // $(".print-share").stick_in_parent({offset_top: 113});

  if ($('.gfield.upload-file .gfield_label').length) {
    $('.gfield.upload-file .gfield_label').after(
      '<span class="file-text">Aucun fichier choisi</span>'
    );
  }
  $('.gfield.upload-file .ginput_container_fileupload input[type="file"]').change(
    function () {
      // var filename = $(this)[0].files.length ? $(this)[0].files[0].name : "Aucun fichier choisi";
      var filename = $(this)[0].files.length ? '' : 'Aucun fichier choisi';
      $('.file-text').text(filename);
    }
  );

  // Fade in blocks
  if ($('.block-fade-out').length) {
    var x = 0;
    $('.block-fade-out').each(function (e) {
      var block = $(this);
      $(window).scroll(function () {
        if (
          !block.hasClass('block-fade-in') &&
          $(window).scrollTop() + $(window).height() > block.offset().top
        ) {
          x++;
          setTimeout(function () {
            block.addClass('block-fade-in');
          }, 500 * x);
        } else {
          x = 0;
          return false;
        }
      });
    });
    $(window).trigger('scroll');
  }

  function isInViewport(el) {
    var elementTop = el.offset().top;
    var elementBottom = elementTop + el.outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
  }

  $('#property_search_form select').each(function () {
    var $control = $(this);
    $control.select2({
      minimumResultsForSearch: -1,
    });
  });

  var $ctriggers = [];
  setInterval(function () {
    if ($ctriggers.length) {
      var $x = $ctriggers.shift();
      if ($x) {
        console.log('trigger :: ' + $x.attr('id'));
        $x.select2({
          minimumResultsForSearch: -1,
        });
      }
    }
  }, 100);

  function updateFilterControl($control) {
    $.post(
      AjaxFrontEnd.ajaxurl,
      {
        action: 'ch_getFiltersList',
        nonce: AjaxFrontEnd.nonce,
        params: $('#property_search_form').serializeArray(),
      },
      function (e) {
        pcomodity_internal_change = 1;
        for (var i in e) {
          var $c = $('#property_search_form select[name="' + i + '"]');
          // console.log('Object :: '+'#property_search_form select[name="'+i+'"]');
          // console.log($c);
          if ($c.length) {
            if (i == 'min_price' || i == 'min_surface') {
              // console.log(' !!! '+i+ ' :: '+$c.attr('name'));
              $c.find('option').prop('disabled', true);

              $c.find('option').each(function (opti, optv) {
                var $copt = $(this);
                // console.log(opti + ' <= '+e[i] + ' :: '+$copt.val());
                // console.log(optv);
                if (opti <= e[i]) {
                  $copt.prop('disabled',false);
                }
              });
            } else if (i == 'max_price' || i == 'max_surface') {
              $c.find('option').prop('disabled', true);

              $c.find('option').each(function (opti, optv) {
                var $copt = $(this);
                // console.log(opti + ' >= '+e[i] + ' :: '+$copt.val());

                if (opti == 0 || opti >= e[i]) {
                  $copt.prop('disabled',false);
                }
              });
            } else {
              //console.log('if ( e[i].length )' + ' :: '+( e[i].length ));
              $c.find('option').prop('disabled', 'disabled');
              if (e[i].length) {
                for (var j = 0; j < e[i].length; j++) {
                  console.log('find :: option[value="'+e[i][j]+'"]');
                  $c.find('option[value="' + e[i][j] + '"]').prop('disabled',false);
                  console.log($c.find('option[value="' + e[i][j] + '"]').prop('disabled'));
                }
                $c.find('option[value="any"]').prop('disabled',false);
              }
            }
             $c.select2({
               minimumResultsForSearch: -1,
             });
            // $ctriggers.push($c);
            // $c.trigger('change');
          } else {
            if (i == 'p_commodity') {
              console.log('-------------  Disable other -------------- ');
              $('#property_search_form input[name="p_commodity[]"]').each(function () {
                var old_v = $(this).prop('checked');
                console.log($(this).attr('id') + ' :: ' + old_v);
                $(this).data('ocheck', old_v);
                $(this).prop('checked', false).parent().addClass('hide');
              });

              if (e[i].length) {
                console.log('-------------  Search -------------- ');
                $(e[i]).each(function (x, y) {
                  // console.log(x+ '::' + y);
                  var $c = $(
                    '#property_search_form input[name="p_commodity[]"][value="' + y + '"]'
                  );
                  // console.log('Object :: '+'#property_search_form input[name="p_commodity[]"][value="'+y+'"]');
                  // console.log($c);
                  if ($c.length) {
                    $c.prop('checked', $c.data('ocheck')).parent().removeClass('hide');
                  }
                });
              }
            }
          }
        }

        pcomodity_internal_change = 0;
      },
      'json'
    );
  }

  if ($('#check-filters').length) {
    updateAllControls();
  }
  function updateAllControls() {
    updateFilterControl($('#city'));
  }

  $('#property_search_form select').on('select2:select', function () {
    updateFilterControl($(this));
  });
  var pcomodity_internal_change = 0;
  $('#property_search_form input[name="p_commodity[]"]').on('change', function () {
    if (!pcomodity_internal_change) {
      updateFilterControl($(this));
    }
  });

  var cancelClick = 1,
    $clickObj = null;
  $('.page-template-page-demande article.content-narrow a').click(function (e) {
    var $clickObj = $(this);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'clic-lien-demande-location-logement-social',
      category: 'demande-location-logement-social',
    });
  });

  // $(document).bind('gform_confirmation_loaded', function(event, formId){

  // 	console.log('dedede');

  // });
});

window.addEventListener('load', function () {
  const sortableColumns = document.querySelectorAll('.table-sort-load');
  sortableColumns.forEach(el => el.click());
});


